import React, { useState } from 'react';
import {
  makeStyles, createStyles, Icon,
} from '@material-ui/core';

import { Col, Row, Button } from '@horizon/components-react';
import { User } from '../../containers/User/User.type';
import { Account } from '../../containers/Accounts/Accounts.type';
import UsersList from '../../containers/User/UsersList.container';
import GetAllUsers from './GetAllUsers.componenet';
import ProjectsChoiceField from '../ProjectsChoiceField/ProjectsChoiceField.component';
import AccountsChoiceField from '../AccountsChoiceField/UsersAccountsChoiceFieldByAspire.component';

type Props = {
    users: User[],
    accounts: Account[],
}

const useStyles = makeStyles(() => createStyles({
  root: {
    margin: '16px 0 0 0',
  },
  select: {
    width: '200px',
  },
}));

export const ListUsers = () => {
  const classes = useStyles();
  const [generateAll, setGenerateAll] = useState(false);
  const [account, setAccount] = useState('');
  const [aspire, setAspire] = useState('');
  return (
    <div className={classes.root}>
      <Row>
        <Col md={3} xs={12}>
          <ProjectsChoiceField setAspire={setAspire} />
        </Col>
        <Col md={3} xs={12}>
          <AccountsChoiceField setAccount={setAccount} aspire={aspire} />
        </Col>
      </Row>
      {!generateAll && (
        <Button
          // contained
          color="primary"
          size="small"
          onClick={() => setGenerateAll(true)}
        >
          <Icon fontSize="small" style={{ marginRight: '5px' }}>cached</Icon>
          Generate all users CSV and e-mail list
        </Button>
      ) }
      {generateAll && <GetAllUsers />}
      {account && <UsersList accountId={account} />}
    </div>
  );
};
