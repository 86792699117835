export type Row = { // used in getColumns
  aspire: string
  version: string
  image: string
  status: string
  product: string
  name: string
}

export type Item = {
  aspire: string
  tools: Tool[]
}

export type Tool = {
  name: string
  status: string
  product: string
  version: string
  image: string
}

export enum ToolStatus {
  OK = 'green',
  KO = 'red',
  UNKNOWN = 'orange',
  NOT_DEPLOYED = 'grey',
}

export type ToolStatusType = keyof typeof ToolStatus;

export type ToolReference = {
  name: string
  version: string
  image: string
  product: string
}
