import { graphql } from 'react-apollo';
import { get } from 'lodash';

import getParametersStoreByArgs from '../../graphql/SSM';
import SSMTable from '../../components/SSMTable/SSMTable.component';
import { SSMParameter, Response, Props } from './SSM.type';

type ChildProps = {
  SSMparameters: SSMParameter[],
}

export default graphql<Props, Response, {}, ChildProps>(getParametersStoreByArgs, {
  options: ({ account, aspire, type }) => ({
    fetchPolicy: 'network-only',
    variables: { account, aspire, type },
  }),
  props: ({ data }) => ({
    SSMparameters: get(data, 'getParametersStoreByArgs', []),
    loading: get(data, 'loading', false),
  }),
})(SSMTable);
