import React from 'react';
import Icon from '@material-ui/core/Icon';
import { $Keys } from 'utility-types';
import { Row, Item, ReportName } from './ReportsTable.types';

const styles = {
  launchIcon: {
    paddingTop: '4px',
    fontSize: '18px',
  },
};

export const mapRowsFromItems = (items: Item[]): Row[] => (
  items.map((i) => (
    i.stacks && i.stacks.flatMap((stack) => (
      stack.drifts && stack.drifts.length > 0 ? stack.drifts.flatMap((drift: any) => (
        {
          name: i.name,
          stackInfo: `${i.name}_${stack.stackId}_${stack.stackName}`,
          stackName: stack.stackName,
          stackId: stack.stackId,
          stackStatus: stack.stackStatus,
          resourceType: drift.resourceType,
          logicalResourceId: drift.logicalResourceId,
          stackResourceDriftStatus: drift.stackResourceDriftStatus,
        })) : {
        name: i.name,
        stackInfo: `${i.name}_${stack.stackId}_${stack.stackName}`,
        stackName: stack.stackName,
        stackId: stack.stackId,
        stackStatus: stack.stackStatus,
        resourceType: null,
        logicalResourceId: null,
        stackResourceDriftStatus: null,
      }
    ))
  )).flat()
);
type Column = {
  title: string,
  field: $Keys<Row>,
  defaultGroupOrder?: number,
  render?: any
}

const getName = (rowData: any) => (
  rowData.name === ReportName.DRIFTS ? rowData.logicalResourceId : rowData.stackName);

const openReportDialog = (setOpenDialog:any, setInfo:any, info: any) => {
  setOpenDialog(true);
  setInfo(info);
};

const addLink = (rowData: any, setOpenDialog: any, setInfo: any, isDrift: boolean = false) => {
  const stackInfo = rowData.stackInfo ? rowData.stackInfo.split('_') : rowData.split('_');
  const name = stackInfo[0];
  const stackId = stackInfo[1];
  const stackName = stackInfo[2];

  const info = { stackId, type: (name === ReportName.DRIFTS) ? 'drifts' : 'stackinfo', logicalResourceId: null };
  if (isDrift) {
    info.logicalResourceId = rowData.logicalResourceId;
  }
  if (name !== ReportName.MISSING) {
    return (
      <span>
        <button type="button" onClick={() => openReportDialog(setOpenDialog, setInfo, info)}>
          {isDrift ? getName(rowData) : stackName}
          {' '}
          <Icon style={styles.launchIcon}>launch</Icon>
        </button>
      </span>
    );
  }
  return (stackName);
};

const getStatus = (rowData: any) => {
  if (rowData.name === ReportName.DRIFTS) {
    return rowData.stackResourceDriftStatus;
  } if (rowData.name === ReportName.ERRORS) {
    return rowData.stackStatus;
  }
  return {};
};

export const getColumns = (setOpenDialog: any, setInfo: any): Column[] => (
  [
    { title: 'Issue', field: 'name', defaultGroupOrder: 0 },
    {
      title: 'Stack Name', field: 'stackInfo', defaultGroupOrder: 1, render: (rowData: any) => addLink(rowData, setOpenDialog, setInfo),
    },
    { title: 'StackName', field: 'logicalResourceId', render: (rowData: any) => addLink(rowData, setOpenDialog, setInfo, true) },
    { title: 'Status', field: 'stackResourceDriftStatus', render: getStatus },
    { title: 'ResourceType', field: 'resourceType' },
  ]
);
