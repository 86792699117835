import { useEffect } from 'react';

import config from '../../config';

const SignIn = () => {
  const clientId = config.Auth.userPoolWebClientId;
  const { domain } = config.Auth.oauth;
  const { redirectSignIn } = config.Auth.oauth;
  const { responseType } = config.Auth.oauth;
  const auth_url = `https://${domain}/oauth2/authorize?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}`;

  useEffect(() => {
    window.location.assign(auth_url);
  });

  return (
    null
  );
};

export default SignIn;
