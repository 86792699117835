import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {
  Button,
} from '@horizon/components-react';
import { Icon } from '@material-ui/core';
import { User } from '../../containers/User/User.type';

const LIST_ACCOUNT = gql`query {
  listAccounts {
      items {
          account_id
          alias
          users{
            id
            name
            passwordLastUsed
            createDate
            arn
            aspire
            path
            firstName
            lastName
            email
            lastUsedAccessKey
          }
      }
  }
}`;

function download_csv(users: User[]) {
  let csv = 'FirstName,LastName,Email,IAM User,AccountID,AccountAlias,Aspire,accessKeyAge\n';
  users.forEach((user: any) => {
    csv += `${user.firstName},${user.lastName},${user.email},${user.name},${user.account_id},${user.alias},"${user.aspire}",${user.lastUsedAccessKey}`;
    csv += '\n';
  });
  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = 'fasttrack_users.csv';
  hiddenElement.click();
}

const generateEmailsListFromUsers = (users: User[]) => users
  .filter(({ email }) => email)
  .map(({ email }) => email)
  .join(';');

export default () => (
  <Query errorPolicy="ignore" query={LIST_ACCOUNT}>
    {({ loading, error, data }: any) => {
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      const usersEnrich: any = [];
      data && data.listAccounts.items.forEach((i: any) => {
        i.users && i.users.forEach((user: any) => {
          // eslint-disable-next-line no-param-reassign
          user.account_id = i.account_id;
          // eslint-disable-next-line no-param-reassign
          user.alias = i.alias;
          usersEnrich.push(user);
        });
      });
      return (
        <div>
          <Button
            contained
            color="primary"
            size="small"
            onClick={() => {
              download_csv(usersEnrich);
            }}
          >
            <Icon fontSize="small" style={{ marginRight: '5px' }}>get_app</Icon>
            Download all users CSV
          </Button>
          <Button
            style={{ marginLeft: '20px' }}
            contained
            color="default"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(generateEmailsListFromUsers(usersEnrich));
            }}
          >
            <Icon fontSize="small" style={{ marginRight: '5px' }}>file_copy</Icon>
            Copy e-mail list to Clipboard
          </Button>
        </div>
      );
    }}
  </Query>

);
