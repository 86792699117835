import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Title } from '@horizon/components-react';

const LIST_PROJECTS = gql`query {
  listProjects {
      items {
          aspire
      }
  }
}`;

export default () => (
  <Query query={LIST_PROJECTS}>
    {({ loading, error, data }: any) => {
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      return (
        <Title color="primary" tag="h1">{data.listProjects.items.length}</Title>
      );
    }}
  </Query>
);
