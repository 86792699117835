import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Stack,
} from '@horizon/components-react';
import { Row } from './AccountsTable.types';
import { Account } from '../../containers/Accounts/Accounts.type';
import { mapRowsFromItems, getColumns } from './AccountsTable.utils';
import { AddAccountDialog, EditAccountDialog } from '../../containers/Accounts/AccountDialog.container';

type Props = {
  accounts: Account[],
}

const styles = (theme: any) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
});

const ListAccounts = (props: Props) => {
  const { accounts } = props;

  const [rows, setRows] = useState<Row[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [accountInEdition, setAccountInEdition] = useState({});

  useEffect(() => {
    setRows(mapRowsFromItems(accounts));
  }, [accounts]);

  const materialTable = (
    <MaterialTable
      options={{
        pageSize: 20,
        grouping: true,
      }}
      components={{
        Groupbar: () => (null),
      }}
      title="Accounts"
      columns={getColumns()}
      data={rows}
      actions={[
        {
          icon: 'add',
          tooltip: 'Add an account',
          isFreeAction: true,
          onClick: () => {
            setOpenAddDialog(true);
          },
        },
        (rowData) => ({
          icon: 'edit',
          tooltip: `Edit account${rowData.application_code ? ' (not available if already hosts a project)' : ''}`,
          onClick: () => {
            setAccountInEdition(rowData);
            setOpenEditDialog(true);
          },
          disabled: !!rowData.application_code,
        }),
      ]}
    />
  );

  return (
    <>
      <AddAccountDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
      />
      <EditAccountDialog
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        account={accountInEdition}
      />
      <Stack spacing="2-x">
        <Container style={{ width: '1OO%' }}>
          {materialTable}
        </Container>
      </Stack>
    </>
  );
};

export default withStyles(styles)(ListAccounts);
