import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import {
  List,
  ListItemText,
  ListItemIcon,
  Divider,
  Card,
  CardContent,
  CardHeader,
} from '@horizon/components-react';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
    backgroundColor: '#f0f4f6',
    height: '100%',
  },
  menuList: {
    '& a': {
      padding: '12px 16px',
      display: 'flex',
      textDecoration: 'none',
      alignItems: 'center',
      color: 'black',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        color: 'black',
      },
    },
  },
};

const Menu = ({ classes }: any) => {
  const [surname, setSurname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    async function UserState() {
      setTimeout(async () => {
        await Auth.currentAuthenticatedUser()
          .then((user) => {
            const connectedUser = get(user, 'signInUserSession.idToken.payload');
            if (connectedUser) {
              setSurname(connectedUser.preferred_username);
              setFirstname(connectedUser.given_name);
              setEmail(connectedUser.email);
            }
          });
      }, 0);
    }
    UserState();
  }, []);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title={`${firstname} ${surname}`} />
        <CardContent>
          {email}
        </CardContent>
      </Card>
      <Divider />
      <List className={classes.menuList}>
        <Link to="/">
          <ListItemIcon><Icon>home</Icon></ListItemIcon>
          <ListItemText>Home</ListItemText>
        </Link>
        <Link to="/projects">
          <ListItemIcon><Icon>apps</Icon></ListItemIcon>
          <ListItemText>Projects</ListItemText>
        </Link>
        <Link to="/accounts">
          <ListItemIcon><Icon>account_tree</Icon></ListItemIcon>
          <ListItemText>Accounts</ListItemText>
        </Link>
        <Link to="/ssm">
          <ListItemIcon><Icon>tune</Icon></ListItemIcon>
          <ListItemText>SSM</ListItemText>
        </Link>
        <Link to="/users">
          <ListItemIcon><Icon>supervisor_account</Icon></ListItemIcon>
          <ListItemText>Users</ListItemText>
        </Link>
        <Link to="/tools">
          <ListItemIcon><Icon>build</Icon></ListItemIcon>
          <ListItemText>Tools</ListItemText>
        </Link>
      </List>
      <Divider />
      <List className={classes.menuList}>
        <Link to="/signout">
          <ListItemText>Sign out</ListItemText>
        </Link>
      </List>
    </div>
  );
};

export default withStyles(styles)(Menu);
