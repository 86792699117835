import { withStyles } from '@material-ui/core/styles';
import { Stack } from '@horizon/components-react';
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';

import { Row } from './SSMTable.types';
import { mapRowsFromItems, getColumns } from './SSMTable.utils';
import { SSMParameter } from '../../containers/SSM/SSM.type';

type Props = {
  classes: any,
  SSMparameters: SSMParameter[],
}

const styles = (theme: any) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    '& a': {
      textDecoration: 'none',
    },
  },
});

const SSMTable = (props: Props) => {
  const { SSMparameters, classes } = props;

  const [rows, setRows] = useState<Row[]>([]);

  useEffect(() => {
    setRows(mapRowsFromItems(SSMparameters));
  }, [SSMparameters]);

  const materialTable = (
    <MaterialTable
      options={{
        pageSize: 20,
      }}
      title="Parameters"
      columns={getColumns()}
      data={rows}
    />
  );

  return (
    <Stack spacing="2-x">
      <div className={classes.table}>
        {materialTable}
      </div>
    </Stack>
  );
};

export default withStyles(styles)(SSMTable);
