import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { ChoiceField } from '@horizon/components-react';

const LIST_Accounts = gql`query {
  listAccounts {
      items {
        account_id
        application_codes
        account_type
      }
  }
}`;

export default (
  { setAccount, aspire }: { setAccount: (account: string) => void,
    aspire: string },
) => {
  const [selectedValue, setSelectedValue] = useState('');
  return (
    <Query query={LIST_Accounts} fetchPolicy="network-only">
      {({ loading, error, data }: any) => {
        if (loading) return 'Loading...';
        if (error) return `Error! ${error.message}`;
        return (
          <ChoiceField
            value={selectedValue}
            label="Accounts"
            options={[
              {
                label: '',
                value: '',
              },
              ...data.listAccounts.items
                .filter((n: any) => n.application_codes && n.application_codes
                  .includes(aspire) !== false && ['ops', 'dev', 'sec'].includes(n.account_type))
                .map((i: any) => (
                  {
                    label: `${i.account_id} - ${i.account_type}`,
                    value: i.account_id,
                  }
                ))]}
            onChange={(value) => {
              setSelectedValue(value);
              setAccount(value);
            }}
          />
        );
      }}
    </Query>
  );
};
