import { Typography } from '@material-ui/core/';
import Icon from '@material-ui/core/Icon';
import {
  Card, CardContent, Col, Container, Loading, Row, Title,
} from '@horizon/components-react';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  project: any,
  loading: any
}

const style = {
  icon: {
    paddingTop: '3px',
    fontSize: '18px',
  },
  link: {
    textDecoration: 'None',
    paddingBottom: '2px',
  },
};

const getStatusCognitoActivation = (status:any) => {
  if (status === undefined || status === null || status === 'null') {
    return {
      text: 'disabled',
      color: 'warning' as 'warning',
    };
  }
  return {
    text: 'enabled',
    color: 'success' as 'success',
  };
};

const ProjectDetail = (props: Props) => {
  const { loading, project } = props;
  if (loading === true) {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Loading dots>
              Loading....
            </Loading>
          </Col>
        </Row>
      </Container>
    );
  }
  const cognitoActivation = getStatusCognitoActivation(project.cognito_airbus_domain);
  const projectName = (project.name !== null) ? project.name : project.aspire;
  return (
    <Container>
      <Link to="/projects/" style={style.link}>
        <Typography>
          <Icon style={style.icon}>arrow_back_ios</Icon>
          Projects
        </Typography>
      </Link>
      <Row>
        <Col xs={12}>
          <Title color="info" tag="h3">
            {projectName}
          </Title>
        </Col>
        <Col xs={4}>
          <Card elevation={8}>
            <CardContent>
              <Title color="info" tag="h4">Aspire</Title>
              <Title color="primary" tag="h3">{project.aspire}</Title>
            </CardContent>
          </Card>
        </Col>
        <Col xs={4}>
          <Card elevation={8}>
            <CardContent>
              <Title color="info" tag="h4">Number of accounts</Title>
              <Title color="primary" tag="h3">{project.accounts.length}</Title>
            </CardContent>
          </Card>
        </Col>
        <Col xs={4}>
          <Card elevation={8}>
            <CardContent>
              <Title color="info" tag="h4">Cognito Airbus Domain</Title>
              <Title color={cognitoActivation.color} tag="h3">{cognitoActivation.text}</Title>
            </CardContent>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectDetail;
