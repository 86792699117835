import React, { useState } from 'react';

import { Container } from '@horizon/components-react';
import SSMInput from '../../containers/SSM/SSMInput.container';
import SSMTable from '../../containers/SSM/SSMTable.container';

const SSMPage = () => {
  const [aspire, setAspire] = useState('');
  const [type, setType] = useState('');
  const [account, setAccount] = useState('');

  return (
    <Container style={{ width: '80%' }}>
      <SSMInput setAccount={setAccount} setAspire={setAspire} setType={setType} />
      <SSMTable account={account} aspire={aspire} type={type} />
    </Container>
  );
};

export default (SSMPage);
