import gql from 'graphql-tag';

export const ListProjectsByToolsQuery = gql`query {
  listProjects {
      items {
          aspire
          tools {
            name
            status
            version
            image
            product
          }
      }
  }
}`;

export const ListToolsReferenceQuery = gql`query {
  listToolsReference {
    name
    version
    image
    product
  }
}`;
