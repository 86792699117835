import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/';
import MaterialTable from 'material-table';
import Icon from '@material-ui/core/Icon';

import { Container, Stack } from '@horizon/components-react';
import { Link } from 'react-router-dom';
import ReportsDialog from '../ReportsDialog/ReportsDialog.component';

import { Row } from './ReportsTable.types';
import { mapRowsFromItems, getColumns } from './ReportsTable.utils';

type Props = {
  classes: any,
  items: any,
  account_id: string,
  aspire: string
}

const style = {
  icon: {
    paddingTop: '3px',
    fontSize: '18px',
  },
  link: {
    textDecoration: 'None',
    paddingBottom: '2px',
  },
};

const styles = (theme: any) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const ReportsDetail = (props: Props) => {
  const [rows, setRows] = useState<Row[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const {
    items, account_id, aspire, classes,
  } = props;
  const [info, setInfo] = useState({ stackId: null, type: null });

  useEffect(() => {
    setRows(mapRowsFromItems(items));
  }, [items]);

  const materialTable = (
    <MaterialTable
      options={{
        pageSize: 20,
        grouping: true,
      }}
      components={{
        Groupbar: () => (null),
      }}
      title={`Report account ${account_id}`}
      columns={getColumns(setOpenDialog, setInfo)}
      data={rows}
    />
  );

  return (
    <Stack spacing="2-x">
      <Container style={{ width: '100%' }}>
        <div>
          <Link to="/projects/" style={style.link}>
            <Typography className={classes.link}>
              <Icon style={style.icon}>arrow_back_ios</Icon>
              Projects
            </Typography>
          </Link>
          <br />
          <br />
          <ReportsDialog
            open={openDialog}
            setOpen={setOpenDialog}
            aspire={aspire}
            accountId={account_id}
            info={info as any}
          />
          {materialTable}
        </div>
      </Container>
    </Stack>
  );
};

export default withStyles(styles)(ReportsDetail);
