import React from 'react';
import { graphql } from 'react-apollo';
import { get } from 'lodash';

import { GetProjectQuery } from '../../graphql/Project';
import ProjectDetail from '../../components/ProjectDetail/ProjectDetail.component';

type ChildProps = {
  aspire: string,
}

export default graphql(GetProjectQuery, {
  options: ({ aspire }: ChildProps) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      aspire,
    },
  }),
  props: ({ data, ownProps }) => ({
    project: get(data, 'getProject', {}),
    loading: get(data, 'loading', false),
    ...ownProps,
  }),
})(({ project, loading }) => (<ProjectDetail project={project} loading={loading} />));
