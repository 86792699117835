import { withStyles } from '@material-ui/core/styles';
import {
  ChoiceField, Col, Container, Row, Wrap,
} from '@horizon/components-react';
import React from 'react';
import ProjectsChoiceField from '../ProjectsChoiceField/ProjectsChoiceField.component';
import AccountsChoiceField from '../AccountsChoiceField/AccountsChoiceField.component';
import { setProps } from '../../containers/SSM/SSM.type';

type Props = setProps;

const styles = (theme: any) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
});

const SSMInput = ({ setAccount, setType, setAspire }: Props) => (
  <Wrap spacing="2-x">
    <Container style={{ width: '100%' }}>
      <Row>
        <Col xs={4}>
          <AccountsChoiceField setAccount={setAccount} />
        </Col>
        <Col xs={4}>
          <ChoiceField
            label="Type"
            options={[
              {
                label: '',
                value: '',
              },
              {
                label: 'app',
                value: 'app',
              },
              {
                label: 'tools',
                value: 'tools',
              },
            ]}
            onChange={(value) => setType(value)}
          />
        </Col>
        <Col xs={4}>
          <ProjectsChoiceField setAspire={setAspire} />
        </Col>
      </Row>
    </Container>
  </Wrap>
);

export default withStyles(styles)(SSMInput);
