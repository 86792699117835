import gql from 'graphql-tag';

export default gql`query GetReport (
    $aspire: String!,
    $account_id: String!
){
    getReport(aspire: $aspire, accountId: $account_id) {
          drifts {
                drifts {
                    resourceType
                    stackResourceDriftStatus
                    logicalResourceId
                }
                stackId
                stackName
          }
          errors {
                stackId
                stackName
                stackStatus
          }
          status
          missing
    }
}`;
