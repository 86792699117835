import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

const SignOut = () => {
  useEffect(() => {
    Auth.signOut();
  }, []);
  return null;
};

export default SignOut;
