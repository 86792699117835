import React from 'react';
import {
  Button,
  Container,
} from '@horizon/components-react';
import { Dialog, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Props } from './ReportsDialog.types';

const styles = () => ({
  container: {
    padding: 15,
    borderRadius: 10,
  },
});

const ReportsDialog = ({
  open, setOpen, aspire, accountId, info,
}: Props) => {
  function handleClose() {
    setOpen(false);
  }

  function redirectLink() {
    const logicalResourceIdParam = info.logicalResourceId ? `&logicalResourceId=${info.logicalResourceId}` : '';
    return `https://eu-west-1.console.aws.amazon.com/cloudformation/home?region=eu-west-1#/stacks/${info.type}${logicalResourceIdParam ? '/info' : ''}?stackId=${info.stackId}${logicalResourceIdParam}`;
  }

  return (
    <Dialog open={open} title="Switch account" onClose={handleClose}>
      <Container style={{ padding: '20px' }}>
        {'In AWS Management Console, '}
        <u>Switch Role</u>
        {' to '}
        <b>
          {`${aspire} - Account ${accountId}`}
        </b>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <a
            href={redirectLink()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button primary>
              Ok
            </Button>
          </a>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export default withStyles(styles)(ReportsDialog);
