import React from 'react';

import AccountsTable from '../../containers/Accounts/AccountsTable.container';
import GenerateConfig from '../GenerateConfig/GenerateConfig.component';

const AccountsPage = () => (
  <>
    <GenerateConfig />
    <AccountsTable />
  </>
);

export default (AccountsPage);
