import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { ChoiceField } from '@horizon/components-react';

const LIST_PROJECTS = gql`query {
  listProjects {
      items {
          aspire
      }
  }
}`;

export default ({ setAspire }: { setAspire: (aspire: string) => void }) => (
  <Query fetchPolicy="network-only" query={LIST_PROJECTS}>
    {({ loading, error, data }: any) => {
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      return (
        <ChoiceField
          label="Projects"
          options={[{
            label: '',
            value: '',
          },
          ...data.listProjects.items.map((i: any) => (
            {
              label: i.aspire.toLowerCase().substring(0, 4),
              value: i.aspire.toLowerCase().substring(0, 4),
            }
          ))]}
          onChange={(value) => setAspire(value)}
        />
      );
    }}
  </Query>
);
