import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import { ApolloProvider } from 'react-apollo';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

import './App.css';
import HomePage from './components/HomePage/HomePage.component';
import ProjectPage from './components/ProjectPage/ProjectPage.component';
import ProjectsPage from './components/ProjectsPage/ProjectsPage.component';
import UsersPage from './components/UsersPage/UsersPage.component';
import ToolsPage from './components/ToolsPage/ToolsPage.component';
import AccountsPage from './components/AccountsPage/AccountsPage.component';
import ReportsPage from './components/ReportsPage/ReportsPage.component';
import SSMPage from './components/SSMPage/SSMPage.component';
import PrivateRoute from './components/PrivateRoute/PrivateRoute.component';
import SignIn from './components/SignIn/SignIn.component';
import SignOut from './components/SignOut/SignOut.component';
import Layout from './components/Layout/Layout.component';
import ScrollToTop from './components/ScrollToTop/ScrollToTop.component';
import config from './config';

Amplify.configure(config);

const client = new AWSAppSyncClient({
  url: config.aws_appsync_graphqlEndpoint,
  region: config.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
  disableOffline: true,
},
{
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

const App = () => (
  <ApolloProvider client={client as any}>
    <Router>
      <ScrollToTop>
        <Layout hasSidebar>
          <Switch>
            <Route path="/signin" component={SignIn} />
            <Route path="/signout" component={SignOut} />
            <PrivateRoute exact path="/" render={() => (<HomePage />)} />
            <PrivateRoute path="/projects/:aspire/reports/:account_id" component={ReportsPage} />
            <PrivateRoute path="/projects/:aspire" component={ProjectPage} />
            <PrivateRoute path="/projects" render={() => (<ProjectsPage />)} />
            <PrivateRoute path="/accounts" render={() => (<AccountsPage />)} />
            <PrivateRoute path="/ssm" render={() => (<SSMPage />)} />
            <PrivateRoute path="/users" render={() => (<UsersPage />)} />
            <PrivateRoute path="/tools" render={() => (<ToolsPage />)} />
          </Switch>
        </Layout>
      </ScrollToTop>
    </Router>
  </ApolloProvider>
);

export default App;
