import React from 'react';
import { $Keys } from 'utility-types';

import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import {
  Row, Item, AccountStatus, ProjectStatus, AccountType,
} from './ProjectsTable.types';

const styles = {
  projectStatus: {
    paddingTop: '2px',
    fontSize: '15px',
  },
  accountId: {
    color: '#011133',
  },
  searchIcon: {
    paddingTop: '4px',
    fontSize: '18px',
    color: '#011133',
  },
  refreshIcon: {
    paddingTop: '4px',
    fontSize: '18px',
    color: '#011133',
  },
  accountType: {
    padding: '2px',
    width: '30px',
    borderRadius: '11px',
  },
};

const getStatus = (status: AccountStatus): ProjectStatus => {
  switch (status) {
  case AccountStatus.INCOMPLETE:
    return ProjectStatus.INCOMPLETE;
  case AccountStatus.FAILED:
    return ProjectStatus.FAILED_DRIFTED;
  case AccountStatus.DRIFTED:
    return ProjectStatus.FAILED_DRIFTED;
  default:
    return ProjectStatus.OK;
  }
};

const isValidStatus = (
  status: ProjectStatus,
) => [ProjectStatus.OK, ProjectStatus.UNKNOWN].includes(status);

const getProjectStatus = (project: any): string => {
  let currentStatus = ProjectStatus.OK;
  project.accounts.forEach((account:any) => {
    if (account.application_codes && account.application_codes.length > 1) {
      currentStatus = ProjectStatus.UNKNOWN;
    } else {
      const status = getStatus(account.report.status);
      if (ProjectStatus.OK !== status) {
        currentStatus = status;
      }
    }
  });
  return currentStatus;
};

const getCognitoDomainActivation = (item: Item): boolean => (
  item.cognito_airbus_domain !== undefined && item.cognito_airbus_domain !== 'null');

const getLength = (array: any): number => (array ? array.length : 0);

export const mapRowsFromItems = (items: Item[]): Row[] => (
  items.map((item) => (item.accounts || [])
    .map((account): Row => {
      const status = account.report ? getStatus(account.report.status) : '';
      const reportStatus = account.report ? `${status}_${getLength(account.report.errors)}_${account.report.missing.length}_${getLength(account.report.drifts)}` : '';
      return {
        aspire: `${item.name}_${item.aspire.toLowerCase().substring(0, 4)}_${getProjectStatus(item)}_${getCognitoDomainActivation(item)}`,
        accountId: account.account_id,
        accountType: account.account_type,
        accountStatus: account.application_codes && account.application_codes.length === 1 ? status : 'gray',
        reportStatus,
      };
    }))).flat();

  type Column = {
    title: string,
    field: $Keys<Row>,
    defaultGroupOrder?: number,
    render: any
  }

const buildLinkToReportDetail = (rowData:any) => {
  const reports: string[] = rowData.reportStatus.split('_');
  const status: string = reports[0];
  const aspireAndStatus: string[] = rowData.aspire.split('_');
  const aspire: string = aspireAndStatus[0];
  const { accountId } = rowData;
  return isValidStatus(status as ProjectStatus)
    ? (
      <span>
        <Icon style={{ ...styles.searchIcon, color: ProjectStatus.OK }}>done</Icon>
        <span>{rowData.accountId}</span>
      </span>
    )
    : (
      <Link to={`/projects/${aspire}/reports/${accountId}`}>
        <Icon title="See details" style={styles.searchIcon}>search</Icon>
        <span style={styles.accountId}>{rowData.accountId}</span>
      </Link>
    );
};

const accountTypeStyle = (rowData:any) => {
  let color = '#99BCE3';
  const { accountType } = rowData;
  switch (accountType) {
  case AccountType.INT:
    color = '#fbb44b';
    break;
  case AccountType.PRO:
    color = '#f2554d';
    break;
  case AccountType.OPS:
    color = '#AAAAAA';
    break;
  default:
    color = '#4796ff';
    break;
  }
  return (<div style={{ ...styles.accountType, textAlign: 'center', backgroundColor: color }}><span>{accountType}</span></div>);
};

const getReportDetail = (rowData:any) => {
  const reports = rowData.reportStatus.split('_');
  const status = reports[0];
  const errors = reports[1] > 0 ? (
    <span>
      <Icon style={{ ...styles.projectStatus, color: ProjectStatus.FAILED_DRIFTED }} title="error">error</Icon>
      {` ${reports[1]} error ${reports[1] > 1 ? '(s)' : ''}`}
    </span>
  ) : null;
  const missing = reports[2] > 0 ? (
    <span>
      <Icon title="missing" style={{ ...styles.projectStatus, color: ProjectStatus.INCOMPLETE }}>report</Icon>
      {` ${reports[2]} missing`}
    </span>
  ) : null;
  const drifts = reports[3] > 0 ? (
    <span>
      <Icon title="drifts" style={{ ...styles.projectStatus, color: ProjectStatus.FAILED_DRIFTED }}>warning</Icon>
      {` ${reports[3]} drift ${reports[3] > 1 ? '(s)' : ''}`}
    </span>
  ) : null;
  return (isValidStatus(status) ? null : (
    <span>
      {` ${errors} ${missing} ${drifts}`}
    </span>
  ));
};

const buildProjectDetailsLink = (aspire:string) => (
  <Link to={`/projects/${aspire}`}>
    <Icon title="See project details" style={styles.searchIcon}>search</Icon>
  </Link>
);

const getProjectInfo = (rowData:any) => {
  const aspireAndStatus = rowData.split('_');
  const name = aspireAndStatus[0];
  const aspire = aspireAndStatus[1];
  const projectStatus = aspireAndStatus[2];
  const projectName = (name !== 'null') ? `${name} - ${aspire}` : aspire;
  return (
    <span>
      <span>
        {projectName}
      </span>
      <Icon style={{ ...styles.projectStatus, color: projectStatus }}>fiber_manual_record</Icon>
      {buildProjectDetailsLink(aspire)}
    </span>
  );
};

export const getColumns = (): Column[] => (
  [
    {
      title: 'Project', field: 'aspire', defaultGroupOrder: 0, render: getProjectInfo,
    },
    { title: 'Projects', field: 'accountId', render: buildLinkToReportDetail },
    { title: 'Type', field: 'accountType', render: accountTypeStyle },
    { title: 'Report', field: 'reportStatus', render: getReportDetail },
  ]
);
