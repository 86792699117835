import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { AddProjectVariables, AddProjectProps } from '../containers/Projects/Projects.type';

export const GetProjectQuery = gql`query (
  $aspire: String!
){
  getProject(aspire: $aspire){
      aspire,
      name,
      cognito_airbus_domain,
      accounts{
          account_id
      }
    }
}`;

export const ListProjectsQuery = gql`query {
    listProjects {
        items {
            aspire,
            name,
            accounts{
                account_id
            }
        }
    }
}`;

export const addProjectToAccountMutation = gql`query {
    addProjectToAccount(account: AccountInput) {
        aspire
    }
}`;
export const ListProjectsReportsQuery = gql`query {
    listProjects {
        items {
            aspire,
            name,
            cognito_airbus_domain,
            accounts{
                account_id
                account_type
                application_codes
                report {
                    drifts {
                        stackName
                        drifts {
                            resourceType
                        }
                    }
                    errors {
                        stackName
                    }
                    missing
                    status
                }
            }
        }
    }
}`;
export const addProjectMutation = gql`query {
    addProject(project: ProjectInput) {
        aspire
    }
}`;

export const AddProjectContainer = graphql<any, void, AddProjectVariables, AddProjectProps>(
  addProjectMutation, {
    options: () => ({
      refetchQueries: [
        {
          query: ListProjectsQuery,
        },
      ],
    }),
    props: ({ mutate, ownProps }) => ({
      addProject: (params) => {
        mutate && mutate({
          variables: params,
        });
      },
      ...ownProps,
    }),
  },
);
