import { $Keys } from 'utility-types';

import { Row } from './AccountsTable.types';
import { Account } from '../../containers/Accounts/Accounts.type';

export const mapRowsFromItems = (items: Account[]): Row[] => (
  items.map((i) => (
    (i.application_codes && i.application_codes.length > 0 ? i.application_codes : ['']).map((appCode) => (
      {
        account_id: i.account_id,
        account_type: i.account_type,
        alias: i.alias,
        application_code: appCode,
      }
    ))
  )).flat()
);

type Column = {
  title: string,
  field: $Keys<Row>,
  defaultGroupOrder?: number,
}
export const getColumns = (): Column[] => (
  [
    { title: 'Account Id', field: 'account_id' },
    { title: 'Type', field: 'account_type' },
    { title: 'Alias', field: 'alias', defaultGroupOrder: 0 },
    { title: 'Aspire code', field: 'application_code' },
  ]
);
