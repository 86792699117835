import React from 'react';
import { graphql } from 'react-apollo';
import { get } from 'lodash';

import { ListProjectsReportsQuery } from '../../graphql/Project';
import ProjectsTable from '../../components/ProjectsTable/ProjectsTable.component';

export default graphql(ListProjectsReportsQuery, {
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
  props: (props) => ({
    items: get(props, 'data.listProjects.items', []),
    loading: get(props, 'data.loading', false),
  }),
})(({ items }) => (
  <ProjectsTable items={items} />
));
