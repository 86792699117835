import React from 'react';
import { graphql } from 'react-apollo';
import { get } from 'lodash';

import GetReportQuery from '../../graphql/Report';
import ReportsDetail from '../../components/ReportsTable/ReportsTable.component';

type ChildProps = {
  aspire: string,
  account_id: string,
}

export default graphql(GetReportQuery, {
  options: ({ aspire, account_id }: ChildProps) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      aspire,
      account_id,
    },
  }),
  props: ({ data, ownProps }) => ({
    item: get(data, 'getReport', {}),
    loading: get(data, 'loading', false),
    ...ownProps,
  }),
})(({ item, account_id, aspire }) => {
  const drifts = (item.drifts && item.drifts.length > 0 ? item.drifts : []) || [];
  const errors = (item.errors && item.errors.length > 0 ? item.errors : []) || [];
  const missing = (item.missing && item.missing.map((m:string) => ({
    stackName: m,
  }))) || [];
  const items = [
    { name: 'Errors', stacks: errors },
    { name: 'Missing', stacks: missing },
    { name: 'Drifts', stacks: drifts }];
  return (<ReportsDetail items={items} account_id={account_id} aspire={aspire} />);
});
