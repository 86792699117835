import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {
  Title, Loading,
} from '@horizon/components-react';

const LIST_ACCOUNT = gql`query {
  listAccounts {
      items {
          billing
      }
  }
}`;

export default () => (
  <Query query={LIST_ACCOUNT}>
    {({ loading, error, data }: any) => {
      if (error) return `Error! ${error.message}`;
      let totalCost = 0;
      const items = data.listAccounts ? data.listAccounts.items : [];
      items.forEach((i: any) => {
        totalCost += i.billing;
      });
      return (
        <div>
          <Title color="primary" tag="h1">
            {totalCost}
            {' $'}
          </Title>
          {loading && (
            <Loading small />
          )}
        </div>
      );
    }}
  </Query>

);
