import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Label,
} from '@horizon/components-react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { AccountDialogProps } from './AccountDialog.types';
import EnvironmentCombobox from './EnvironmentsCombobox.component';

const styles = () => ({
  dialog: {
    borderRadius: 10,
  },
  disabledTextInput: {
    color: 'gray',
  },
  fieldsContainer: {
    div: {
      padding: 130,
    },
  },
  error: {
    color: '#e4002b',
    fontSize: 12,
    marginTop: 12,
  },
});

type Errors =
  {
    errorType: 'DDB_ERROR',
    message: string,
    errorInfo: any,
  }
  |
  {
    errorType: 'INVALID_FIELD',
    message: string,
    errorInfo: {
      alias?: string,
      accountId?: string,
      type?: string,
    }
  }
  |
  undefined

const AddAccountDialog = ({
  classes, open, setOpen, addOrEditAccount, isAddAccount, account,
}: AccountDialogProps) => {
  const [alias, setAlias] = useState<string>();
  const [type, setType] = useState<string>();
  const [accountId, setAccountId] = useState<string|undefined>();
  const [errors, setErrors] = useState<Errors>();

  const initForm = () => {
    if (!isAddAccount) {
      setAlias(account.alias);
      setType(account.account_type);
      setAccountId(account.account_id);
    } else {
      setAlias('');
      setType('');
      setAccountId('');
    }
  };

  useEffect(initForm, [account]);

  function handleClose() {
    setErrors(undefined);
    initForm();
    setOpen(false);
  }

  function handleSave() {
    addOrEditAccount({
      account: {
        account_id: accountId || undefined,
        alias: alias || undefined,
        ...(type ? { type } : null),
      },
    })
      .then(() => {
        handleClose();
      })
      .catch((e: any) => {
        setErrors(e.graphQLErrors[0]);
        console.error(e.graphQLErrors[0]);
      });
  }

  const getFieldErrorMessage = (field: 'accountId' | 'alias' | 'type') => (errors && errors.errorType === 'INVALID_FIELD' && errors.errorInfo[field]) || undefined;

  return (
    <Dialog open={open} title={`${isAddAccount ? 'Add an' : 'Edit'} account`} onClose={handleClose} className={classes.dialog}>
      <DialogContent>
        {isAddAccount
          ? (
            <TextField
              label="Account id"
              value={accountId}
              onChange={(value) => {
                setAccountId(value);
              }}
              error={!!getFieldErrorMessage('accountId')}
              errorText={getFieldErrorMessage('accountId')}
            />
          )
          : (
            <div>
              <Label>Account id</Label>
              {accountId}
              <br />
              <br />
            </div>
          )}
        <TextField
          label="Alias"
          placeholder="airbus-prod-10027"
          value={alias}
          onChange={(value) => {
            setAlias(value);
          }}
          error={!!getFieldErrorMessage('alias')}
          errorText={getFieldErrorMessage('alias')}
        />
        <EnvironmentCombobox
          type={type || ''}
          setType={setType}
          error={!!getFieldErrorMessage('type')}
          errorText={getFieldErrorMessage('type')}
        />
        <div className={classes.error}>
          {errors && errors.errorType !== 'INVALID_FIELD' && 'Failed to save account'}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button primary onClick={handleSave}>
          {isAddAccount ? 'Add account' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AddAccountDialog);
