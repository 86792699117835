import React from 'react';
import { graphql } from 'react-apollo';
import { get } from 'lodash';

import { ListProjectsQuery } from '../../graphql/Project';
import SSMInput from '../../components/SSMInput/SSMInput.component';
import { setProps } from './SSM.type';

type ChildProps = setProps & {
  items: any[],
  loading: boolean,
}

export default graphql<setProps, Response, {}, ChildProps>(ListProjectsQuery, {
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data, ownProps }) => ({
    items: get(data, 'listAccounts.items', []),
    loading: get(data, 'loading', false),
    ...ownProps,
  }),
})(({
  setAccount, setAspire, setType,
}) => (
  <>
    <SSMInput
      setAccount={setAccount}
      setAspire={setAspire}
      setType={setType}
    />
  </>
));
