import { makeStyles, createStyles } from '@material-ui/core/styles';

import { API, graphqlOperation } from 'aws-amplify';
import {
  TextField, Col, Container, Row, Button,
} from '@horizon/components-react';
import Icon from '@material-ui/core/Icon';
import {
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
} from '@material-ui/core';
import React, { useState } from 'react';

import ProjectsChoiceField from '../ProjectsChoiceField/ProjectsChoiceField.component';
import AccountsChoiceField from '../AccountsChoiceField/AccountsChoiceFieldByAspire.component';
import { PromoteUserComponentProps } from '../../containers/User/User.type';
import Loader from '../Loader/Loader.component';
import { PromoteResultSub } from '../../graphql/User';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const PromoteUser = ({
  setAccount,
  setAspire,
  account,
  aspire,
  postMutation,
}: PromoteUserComponentProps) => {
  const classes = useStyles();
  const [stateMachineId, setStateMachineId] = useState<string | null>(null);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState('');
  const [name, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [rational, setRational] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <Container style={{ width: '100%' }}>
        <Row>
          <Col md={3} xs={12}>
            <TextField
              type="text"
              label="name"
              required
              onChange={(value) => setUsername(value)}
            />
          </Col>
          <Col md={3} xs={12}>
            <TextField
              type="email"
              label="User email"
              required
              onChange={(value) => setEmail(value)}
            />
          </Col>
          <Col md={3} xs={12}>
            <ProjectsChoiceField setAspire={setAspire} />
          </Col>
          <Col md={3} xs={12}>
            <AccountsChoiceField setAccount={setAccount} aspire={aspire} />
          </Col>
          <Col md={12} xs={12} alignSelf="center">
            <TextField
              multiline
              required
              label="Rational"
              placeholder="Explain the reason ..."
              onChange={(value) => setRational(value)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3} xs={12} alignSelf="center">
            <Button
              size="small"
              primary
              onClick={() => {
                setLoading(true);
                setStateMachineId(null);
                postMutation({
                  user: {
                    aspire,
                    account,
                    email,
                    rational,
                    name,
                  },
                }).then((data: any) => {
                  const smId = data && data.data.promoteUser.id;
                  setStateMachineId(smId);
                  return data.data.promoteUser.id;
                }).then((queryId: string) => {
                  // eslint-disable-next-line no-console
                  console.log(`queryId: ${queryId}`);
                  const subscription = (API.graphql(
                    graphqlOperation(PromoteResultSub, { queryId }),
                  ) as any).subscribe({
                    next: (data: any) => {
                      // eslint-disable-next-line no-console
                      console.log(`data: ${data}`);
                      setLoading(false);
                      if (data.value.data.onPromoteResult) {
                        setStatus(data.value.data.onPromoteResult.status);
                        setResult(data.value.data.onPromoteResult.result);
                        setOpen(true);
                        subscription.unsubscribe();
                      }
                    },
                  });
                }).catch((e: any) => {
                  console.error(e);
                });
              }}
            >
              <Icon style={{ marginRight: '6px' }}>add</Icon>
              Promote User
            </Button>
          </Col>
          <Col md={3} xs={12} alignSelf="center">
            {loading && <Loader />}
            {stateMachineId && !loading && (
              <div>
                <Dialog open={open} title="test" onClose={handleClose}>
                  <DialogTitle>{`Result: ${status}`}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {decodeURI(result)}
                    </DialogContentText>
                    <DialogContentText>
                      <a
                        style={{ textDecoration: 'none' }}
                        href={`https://eu-west-1.console.aws.amazon.com/states/home?region=eu-west-1#/executions/details/${stateMachineId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button contained color="secondary" size="extra-small">
                          <Icon style={{ marginRight: '6px' }}>exit_to_app</Icon>
                          StepFunction Result
                        </Button>
                      </a>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button contained color="secondary" onClick={handleClose}>Ok</Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PromoteUser;
