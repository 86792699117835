import React from 'react';
import { createStyles, makeStyles, Icon } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import { $Keys } from 'utility-types';
import { Button } from '@horizon/components-react';

import { ListUsersChildProps, User } from '../../containers/User/User.type';

const useStyles = makeStyles(() => createStyles({
  error: {
    color: 'red',
  },
  loader: {
    alignSelf: 'center',
  },
  list: {
  },
  item: {
    padding: '16px',
    margin: '16px 0',
    '&:last-child': {
      margin: '0',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  table: {
    marginTop: '20px',
  },
}));

type Column = {
  title: string,
  field: $Keys<User>,
  render?: (row: User) => string | JSX.Element,
}
export const getColumns = (): Column[] => (
  [
    { title: 'FirstName', field: 'firstName' },
    { title: 'LastName', field: 'lastName' },
    { title: 'Email', field: 'email' },
    { title: 'IAM User', field: 'name' },
    { title: 'AccountID', field: 'account_id' },
    { title: 'AccountAlias', field: 'alias' },
    { title: 'Aspire', field: 'aspire' },
    { title: 'accessKeyAge', field: 'lastUsedAccessKey' },
  ]
);

const generateEmailsListFromUsers = (users: User[]) => users
  .filter(({ email }) => email)
  .map(({ email }) => email)
  .join(';');

function download_csv(users: User[]) {
  let csv = 'FirstName,LastName,Email,IAM User,AccountID,AccountAlias,Aspire,accessKeyAge\n';
  users.forEach((user: any) => {
    csv += `${user.firstName},${user.lastName},${user.email},${user.name},${user.account_id},${user.alias},${user.aspire},${user.lastUsedAccessKey}`;
    csv += '\n';
  });
  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = `fasttrack_${users[0].account_id}_users.csv`;
  hiddenElement.click();
}

export const UsersList = ({ users, error, loading }: ListUsersChildProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {loading && <CircularProgress className={classes.loader} />}
      <div className={classes.list}>
        {error && <div className={classes.error}>Could not load users list</div>}
        {!error && !loading && (
          <div>
            <div style={{ marginTop: '20px' }}>
              <Button
                contained
                color="primary"
                size="small"
                onClick={() => {
                  download_csv(users);
                }}
              >
                <Icon fontSize="small" style={{ marginRight: '5px' }}>get_app</Icon>
                {`Download account ${users[0].account_id} users CSV`}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                contained
                color="default"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(generateEmailsListFromUsers(users));
                }}
              >
                <Icon fontSize="small" style={{ marginRight: '5px' }}>file_copy</Icon>
                {`Copy account ${users[0].account_id} e-mail list to Clipboard`}
              </Button>
              <div className={classes.table}>
                <MaterialTable
                  options={{
                    pageSize: 20,
                  }}
                  title="Users list"
                  columns={getColumns()}
                  data={users}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
