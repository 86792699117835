import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Loading,
} from '@horizon/components-react';

const styles: any = () => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

type Props = {
  classes: any,
}

const Loader = (props: Props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Loading>Loading...</Loading>
    </div>
  );
};

export default withStyles(styles)(Loader);
