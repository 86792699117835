import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';

import {
  Container,
  Stack,
} from '@horizon/components-react';
import Icon from '@material-ui/core/Icon';
import { Row, ProjectStatus } from './ProjectsTable.types';
import { mapRowsFromItems, getColumns } from './ProjectsTable.utils';

const style = {
  statusLegend: {
    fontSize: '0.875rem',
    padding: '5px',
  },
  statusIcon: {
    marginBottom: '-5px',
  },
};

type Props = {
  items: any[],
}

const styles = (theme: any) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
});

const ListProjects = (props: Props) => {
  const [rows, setRows] = useState<Row[]>([]);
  const { items } = props;
  // eslint-disable-next-line
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setRows(mapRowsFromItems(items));
  }, [items]);

  const materialTable = (
    <MaterialTable
      options={{
        pageSize: 20,
        grouping: true,
      }}
      components={{
        Groupbar: () => (null),
      }}
      title="Projects"
      columns={getColumns()}
      data={rows}
      actions={[
        {
          icon: 'add',
          tooltip: 'Add project',
          isFreeAction: true,
          onClick: () => {
            setOpenDialog(true);
          },
        },
      ]}
    />
  );

  return (
    <Stack spacing="2-x">
      <Container style={{ width: '1OO%' }}>
        <div style={style.statusLegend}>
          <b>Status:</b>
          {' '}
          <Icon style={{ ...style.statusIcon, color: ProjectStatus.UNKNOWN }}>
            fiber_manual_record
          </Icon>
          <span>Not Scanned</span>
          <Icon style={{ ...style.statusIcon, color: ProjectStatus.OK }}>
            fiber_manual_record
          </Icon>
          <span>Valid</span>
          <Icon style={{ ...style.statusIcon, color: ProjectStatus.INCOMPLETE }}>
            fiber_manual_record
          </Icon>
          <span>Incomplete</span>
          <Icon style={{ ...style.statusIcon, color: ProjectStatus.FAILED_DRIFTED }}>
            fiber_manual_record
          </Icon>
          <span>Drifts/Fails</span>
        </div>
        {materialTable}
      </Container>

    </Stack>
  );
};

export default withStyles(styles)(ListProjects);
