import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {
  Title,
  Card,
  Row,
  Col,
  CardContent,
  Divider,
  ChoiceField,
} from '@horizon/components-react';

const LIST_ACCOUNT = gql`query {
  listAccounts {
      items {
          account_id
          alias
          account_type
          application_codes
          billing
      }
  }
}`;

export default () => (
  <Query query={LIST_ACCOUNT}>
    {({ loading, error, data }: any) => {
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      return (data.listAccounts.items.map((i: any) => (
        <Col xs={12} key={i.account_id}>
          <Card elevation={8}>
            <CardContent>
              <Title color="primary" tag="h4">
                Account
                {' '}
                {i.account_id}
              </Title>

              <Row>
                <Col xs={12}>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col sm={6} md={4} lg={3} xl={2}>

                  <Title color="info" tag="h6">Type</Title>
                  <Title color="primary" tag="h5">{i.account_type}</Title>

                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>

                  <Title color="info" tag="h6">Alias</Title>
                  <Title color="primary" tag="h5">{i.alias}</Title>

                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>

                  <Title color="info" tag="h6">Cost</Title>
                  {(i.billing) ? (
                    <Title color="success" tag="h5">
                      {`${i.billing} $`}
                    </Title>
                  ) : (
                    <Title color="error" tag="h5">
                      N/A
                    </Title>
                  )}
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>

                  <Title color="info" tag="h6">Linked Projects</Title>
                  {i.application_codes
                    && (
                      <ChoiceField
                        options={i.application_codes.map((appCode: string) => (
                          {
                            label: appCode.toLowerCase().substring(0, 4),
                            value: appCode.toLowerCase().substring(0, 4),
                          }
                        ))}
                      />
                    )}

                </Col>
              </Row>
            </CardContent>
          </Card>
        </Col>
      )));
    }}
  </Query>
);
