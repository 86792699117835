import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Logo,
  AppTitle,
  CssBaseline,
  ThemeProvider,
  IconButton,
} from '@horizon/components-react';
import Icon from '@material-ui/core/Icon';
import { pink } from '@material-ui/core/colors';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

import Menu from '../Menu/Menu.component';

const styles = (theme: any) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: 'white',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: '240px',
  },
  content: {
    width: '100%',
    padding: '70px 16px 16px',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
      margin: 0,
    },
  },
  sidebar: {
    width: '240px',
    [theme.breakpoints.down('sm')]: {
      width: '0',
    },
  },
  footer: {
    display: 'block',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    minHeight: '54px !important',
  },
});

type Props = {
  classes: any,
  // eslint-disable-next-line react/require-default-props
  hasSidebar?: boolean,
  // eslint-disable-next-line react/require-default-props
  children?: any,
}

const theme = createMuiTheme({
  palette: {
    primary: pink,
  },
});

const Layout = (
  { classes, hasSidebar = false, children = '' }: Props,
) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme="skywise">
        <div className={classes.root}>
          <CssBaseline />
          <AppBar className={classes.appBar}>
            <Toolbar disableGutters>
              <Hidden smDown>
                <Logo />
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  color="white"
                  onClick={() => setShowDrawer(!showDrawer)}
                >
                  <Icon>menu</Icon>
                </IconButton>
              </Hidden>
              <AppTitle style={{ flexGrow: 1 }}>Skywise</AppTitle>
            </Toolbar>
          </AppBar>
          {hasSidebar && (
            <Hidden smDown>
              <Drawer
                className={classes.drawer}
                anchor="left"
                color="light"
                variant="permanent"
              >
                <div className={classes.toolbar} />
                <Menu />
              </Drawer>
            </Hidden>
          )}
          {hasSidebar && (
            <Hidden mdUp>
              <nav className={classes.sidebar}>
                <Drawer
                  className={classes.drawer}
                  anchor="left"
                  open={showDrawer}
                  onClose={() => setShowDrawer(false)}
                >
                  <Menu />
                </Drawer>
              </nav>
            </Hidden>
          )}
          <main className={classes.content}>
            {children}
            <footer className={classes.footer} />
          </main>
        </div>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(Layout);
