import { graphql, compose } from 'react-apollo';
import { get } from 'lodash';

import { ListProjectsByToolsQuery, ListToolsReferenceQuery } from '../../graphql/Tool';
import ToolsTable from '../../components/ToolsTable/ToolsTable.component';
import { Item, ToolReference } from '../../components/ToolsTable/ToolsTable.types';

type ChildProps = {
  loading?: boolean,
  items: Item[],
}

type Response = {
  listProjects?: {
    items?: Item[]
  },
}

type ResponseRef = {
  loading?: boolean,
  listToolsReference?: ToolReference[]
}

export default compose(
  graphql<{}, Response, {}, ChildProps>(ListProjectsByToolsQuery, {
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data }) => ({
      items: get(data, 'listProjects.items', []),
      loading: get(data, 'loading', false),
    }),
  }),
  graphql<{}, ResponseRef, {}, ResponseRef>(ListToolsReferenceQuery, {
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data }) => ({
      listToolsReference: get(data, 'listToolsReference', []),
      loading: get(data, 'loading', false),
    }),
  }),
)(ToolsTable);
