import { graphql, compose } from 'react-apollo';
import { get } from 'lodash';

import { ListAccountsQuery, AddAccountContainer } from '../../graphql/Account';
import AccountsTable from '../../components/AccountsTable/AccountsTable.component';
import { ListAccountsChildProps, ListAccountsResponse } from './Accounts.type';

export default compose(
  graphql<{}, ListAccountsResponse, {}, ListAccountsChildProps>(ListAccountsQuery, {
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data }) => ({
      accounts: get(data, 'listAccounts.items', []),
      loading: get(data, 'loading', false),
    }),
  }),
  AddAccountContainer,
)(AccountsTable);
