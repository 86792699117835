import { graphql, compose } from 'react-apollo';
import { get } from 'lodash';

import { ListUsersChildProps, ListUsersContainerProps, ListUsersResponse } from './User.type';
import { ListUsersByAccountIdQuery } from '../../graphql/User';

import { UsersList } from '../../components/ListUsers/UsersList.component';

export default compose(graphql<ListUsersContainerProps, ListUsersResponse, {}, ListUsersChildProps>(
  ListUsersByAccountIdQuery,
  {
    options: ({ accountId }) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        accountId,
      },
    }),
    props: ({ data }) => {
      const loading = get(data, 'loading', false);
      const error = get(data, 'error', undefined);
      const users = (error || loading) ? [] : get(get(data, 'getAccount', []), 'users', []);
      const account_id = (error || loading) ? [] : get(get(data, 'getAccount', []), 'account_id', []);
      const alias = (error || loading) ? [] : get(get(data, 'getAccount', []), 'alias', []);
      const usersEnrich: any = [];
      users.forEach((user: any) => {
        // eslint-disable-next-line no-param-reassign
        user.account_id = account_id;
        // eslint-disable-next-line no-param-reassign
        user.alias = alias;
        usersEnrich.push(user);
      });
      return {
        users: (error || loading) ? [] : usersEnrich,
        loading,
        error,
      };
    },
  },
))(UsersList);
