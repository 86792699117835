import React from 'react';
import { graphql } from 'react-apollo';

import PromoteUser from '../../components/PromoteUser/PromoteUser.component';
import { PromoteUserMutation } from '../../graphql/User';
import { PromoteUserContainerProps, PromoteUserComponentProps } from './User.type';

export default graphql<PromoteUserContainerProps, {}, {}, PromoteUserComponentProps>(
  PromoteUserMutation,
  {
    props: ({ mutate, ownProps }) => ({
      postMutation: (params: any) => (
        mutate && mutate({
          variables: params,
        })
      ),
      ...ownProps,
    }),
  },
)(({
  postMutation,
  setAccount,
  account,
  setAspire,
  aspire,
}) => (
  <>
    <PromoteUser
      setAccount={setAccount}
      account={account}
      setAspire={setAspire}
      aspire={aspire}
      postMutation={postMutation}
    />
  </>
));
