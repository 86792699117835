import React, { useState } from 'react';
import { Query } from 'react-apollo';
import {
  Container, Button, ChoiceField, Row, Col,
} from '@horizon/components-react';
import { GenerateConfigQuery } from '../../graphql/GenerateConfigQuery';
import { ListAccountsQuery } from '../../graphql/Account';

const RenderGenerateConfig = (datas: any): any => (
  <Query query={GenerateConfigQuery} variables={{ accounts: datas.datas.accounts, config_type: datas.datas.config_type }} fetchPolicy="network-only">
    {({ loading, error, data }: any) => {
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      return (
        <Button
          style={{ marginTop: '20px' }}
          contained
          color="primary"
          size="small"
          onClick={() => {
            navigator.clipboard.writeText(data.getGeneratedConf);
          }}
        >
          COPY TO CLIPBOARD
        </Button>
      );
    }}
  </Query>
);

const GenerateConfig = () => {
  const [configType, setConfigType] = useState('browser');
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <ChoiceField
            label="Config type to generate"
            options={[
              {
                label: 'Config for chrome extension',
                value: 'browser',
              },
              {
                label: 'Config for credential file',
                value: 'credential',
              },
            ]}
            onChange={(value: string) => setConfigType(value)}
          />
        </Col>
        <Col xs={3}>
          <Query query={ListAccountsQuery} fetchPolicy="network-only">
            {({ loading, error, data }: any) => {
              if (loading) return 'Loading...';
              if (error) return `Error! ${error.message}`;
              return (
                <RenderGenerateConfig
                  datas={{ accounts: data.listAccounts.items, config_type: configType }}
                />
              );
            }}
          </Query>
        </Col>
      </Row>
    </Container>
  );
};

export default (GenerateConfig);
