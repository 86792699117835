import { graphql, compose } from 'react-apollo';
import { get } from 'lodash';

import { ListAccountsResponse, ListAccountsChildProps, Account } from '../Accounts/Accounts.type';
import { ListAccountsQuery } from '../../graphql/Account';

import { ListUsers } from '../../components/ListUsers/ListUsers.component';

const filterOnDevAccounts = (account: Account) => account.account_type === 'dev';

export default compose(
  graphql<{}, ListAccountsResponse, {}, ListAccountsChildProps>(ListAccountsQuery, {
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data }) => ({
      accounts: get(data, 'listAccounts.items', []).filter(filterOnDevAccounts),
      loading: get(data, 'loading', false),
    }),
  }),
)(ListUsers);
