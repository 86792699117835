import React from 'react';
import { $Keys } from 'utility-types';
import Icon from '@material-ui/core/Icon';
import ReactTooltip from 'react-tooltip';

import { Col, Row as RowUI } from '@horizon/components-react';
import {
  Row, Item, Tool, ToolStatus, ToolStatusType, ToolReference,
} from './ToolsTable.types';

const styles = {
  projectStatus: {
    paddingTop: '2px',
    fontSize: '15px',
  },
};

const getToolByProduct = (toolsReference: ToolReference[], product: string):
  ToolReference => (
  toolsReference.find((tool: ToolReference) => tool.product === product) || {
    name: '',
    version: '',
    image: '',
    product: '',
  }
);

const getProjectStatus = (item: Item, toolsReference: ToolReference[]): string => {
  let availibilityStatus = ToolStatus.OK;
  let koFound = false;
  item.tools.forEach((tool: Tool) => {
    if (!koFound && tool.image !== 'Not deployed') {
      const currentStatus = ToolStatus[tool.status as ToolStatusType];
      if (currentStatus === ToolStatus.KO) {
        availibilityStatus = ToolStatus.KO;
        koFound = true;
      } else if (currentStatus === ToolStatus.UNKNOWN) {
        availibilityStatus = ToolStatus.UNKNOWN;
      }
    }
  });
  let imageStatus = 'green';
  item.tools.forEach((tool: Tool) => {
    toolsReference.forEach((toolRef: ToolReference) => {
      if (tool.image.toLowerCase() === 'unknown' && imageStatus !== 'red') {
        imageStatus = 'orange';
      } else if (tool.image !== toolRef.image && tool.product === toolRef.product
        && tool.image !== 'Not deployed') {
        imageStatus = 'red';
      }
    });
  });
  let versionStatus = 'green';
  item.tools.forEach((tool: Tool) => {
    toolsReference.forEach((toolRef: ToolReference) => {
      if (tool.version.toLowerCase() === 'unknown' && versionStatus !== 'red') {
        versionStatus = 'orange';
      } else if (!tool.version.startsWith(toolRef.version) && tool.product === toolRef.product
        && tool.version !== 'Not deployed') {
        versionStatus = 'red';
      }
    });
  });
  const status = `${versionStatus}-${imageStatus}-${availibilityStatus}`;
  return status;
};

export const mapRowsFromItems = (items: Item[], toolsReference: ToolReference[],
  toolsSelected: String[] | undefined): Row[] => (
  items.filter((item: Item) => item.tools.find((tool: Tool) => !toolsSelected || (toolsSelected.includes(tool.product) && tool.version !== 'Not deployed')))
    .map((item) => (item.tools || [])
      .map((tool): Row => {
        const projectStatus = getProjectStatus(item, toolsReference);
        return {
          aspire: `${item.aspire}-${projectStatus}`,
          version: `${tool.version}-${getToolByProduct(toolsReference, tool.product).version}`,
          image: `${tool.image}-${getToolByProduct(toolsReference, tool.product).image}`,
          product: tool.product,
          status: tool.status,
          name: tool.name,
        };
      }))).flat();

type Column = {
  title: string,
  field: $Keys<Row>,
  defaultGroupOrder?: number,
  render?: any
}

const renderStatus = (rowData: any) => {
  let statusValue = 'grey';
  const image = rowData.image.split('-')[0];

  if (image !== 'Not deployed') {
    const status = rowData.status as ToolStatusType;
    statusValue = ToolStatus[status];
  }
  return (
    <span>
      <Icon style={{ ...styles.projectStatus, color: statusValue }}>fiber_manual_record</Icon>
    </span>
  );
};

const renderVersion = (rowData: any) => {
  const version = rowData.version.split('-')[0];
  const latest = rowData.version.split('-')[1];
  let color;
  if (version.toLowerCase() === 'unknown') {
    color = 'orange';
  } else if (version === 'Not deployed') {
    color = 'grey';
  } else {
    color = (version.startsWith(latest) ? 'green' : 'red');
  }
  return (
    <span style={{ color }}>
      {version}
    </span>
  );
};

const renderImage = (rowData: any) => {
  const image = rowData.image.split('-')[0];
  const latest = rowData.image.split('-')[1];
  let color;
  if (image.toLowerCase() === 'unknown') {
    color = 'orange';
  } else if (image === 'Not deployed') {
    color = 'grey';
  } else {
    color = (image === latest ? 'green' : 'red');
  }
  return (
    <span>
      <span data-tip={image} style={{ color }}>
        {image.substring(0, 15)}
        {!['Unknown', 'Not deployed'].includes(image) ? '[..]' : null}
      </span>
      <ReactTooltip />
    </span>
  );
};

const renderName = (rowData: any) => {
  const aspire = rowData.aspire.split('-')[0];

  if (aspire !== 'core') {
    return (
      <span><a href={`https://devops.fasttrack.airbus.com/${aspire}/${rowData.name}`} target="_black">{rowData.name.charAt(0).toUpperCase() + rowData.name.slice(1)}</a></span>
    );
  }
  return (<span>{rowData.name.charAt(0).toUpperCase() + rowData.name.slice(1)}</span>);
};

const renderAspire = (rowData: any) => {
  const aspire = rowData.split('-')[0];
  const versionStatus = rowData.split('-')[1];
  const imageStatus = rowData.split('-')[2];
  const availibilityStatus = rowData.split('-')[3];
  return (
    <span>
      <span>{aspire}</span>
      <RowUI>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          <div />
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          <div style={{ marginLeft: '50px', marginTop: '-35px' }}>
            <Icon style={{ ...styles.projectStatus, color: versionStatus }}>
              fiber_manual_record
            </Icon>
          </div>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          <div style={{ marginLeft: '50px', marginTop: '-35px' }}>
            <Icon style={{ ...styles.projectStatus, color: imageStatus }}>fiber_manual_record</Icon>
          </div>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          <div style={{ marginLeft: '50px', marginTop: '-35px' }}>
            <Icon style={{ ...styles.projectStatus, color: availibilityStatus }}>
              fiber_manual_record
            </Icon>
          </div>
        </Col>
      </RowUI>
    </span>
  );
};

export const getColumns = (): Column[] => (
  [
    {
      title: 'Aspire', field: 'aspire', render: renderAspire, defaultGroupOrder: 0,
    },
    { title: 'Name', field: 'name', render: renderName },
    { title: 'Version', field: 'version', render: renderVersion },
    { title: 'Image', field: 'image', render: renderImage },
    { title: 'Availability', field: 'status', render: renderStatus },
  ]
);
