import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';

import {
  Container,
  Stack, Card, CardContent, Col, Divider, Title, Row as RowUI,
} from '@horizon/components-react';
import ReactTooltip from 'react-tooltip';
import { Row, ToolReference } from './ToolsTable.types';
import { mapRowsFromItems, getColumns } from './ToolsTable.utils';
import Jenkins from '../../assets/img/jenkins.png';
import Sonarqube from '../../assets/img/sonarqube.png';
import Nexus from '../../assets/img/nexus.png';

type Props = {
  classes: any,
  items: any[],
  listToolsReference: any[],
}

const styles = (theme: any) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  center: {
    'text-align': 'center',
  },
  product: {
    minHeight: '96px',
  },
  flexContainer: {
    display: 'flex',
    'flex-wrap': 'wrap',
  },
  removeShadow: {
    boxShadow: 'none',
  },
});

const getProductImage = (product: string) => {
  let image;

  switch (product) {
  case 'jenkins': {
    image = (<img height="64" src={Jenkins} alt={product} />);
    break;
  }
  case 'sonarqube': {
    image = (<img width="64" src={Sonarqube} alt={product} />);
    break;
  }
  case 'nexus': {
    image = (<img width="64" src={Nexus} alt={product} />);
    break;
  }
  default: {
    image = null;
    break;
  }
  }
  return image;
};

const ListTools = (props: Props) => {
  const [rows, setRows] = useState<Row[]>([]);
  const { items, listToolsReference } = props;
  const [toolsSelected, setToolsSelected] = useState<String[] | undefined>(undefined);

  useEffect(() => {
    setRows(mapRowsFromItems(items, listToolsReference, toolsSelected));
    if (listToolsReference.length > 0) {
      if (toolsSelected === undefined) {
        const toolsName = listToolsReference.map((tool: ToolReference) => tool.name);
        setToolsSelected(toolsName);
      } else {
        setToolsSelected(toolsSelected);
      }
    }
  }, [items, listToolsReference, toolsSelected]);

  const selectTool = (toolname:String) => {
    if (toolsSelected) {
      if (!toolsSelected.includes(toolname)) {
        setToolsSelected([...toolsSelected, toolname]);
      } else {
        setToolsSelected(toolsSelected.filter((t: String) => t !== toolname));
      }
    }
  };

  const materialTable = (
    <MaterialTable
      options={{
        pageSize: 20,
        grouping: true,
      }}
      components={{
        Groupbar: () => (null),
      }}
      title="Tools"
      columns={getColumns()}
      data={rows}
    />
  );

  const toolsReference = listToolsReference.map((tool) => (
    <Col sm={6} md={6} lg={4} xl={4} key={tool.name}>
      <Card elevation={8} onClick={() => selectTool(tool.product)} className={toolsSelected && !toolsSelected.includes(tool.name) ? props.classes.removeShadow : ''}>
        <CardContent className={props.classes.product}>
          <Title color="primary" tag="h4">
            {tool.name.charAt(0).toUpperCase() + tool.name.slice(1)}
          </Title>
          <RowUI>
            <Col xs={12}>
              <Divider />
            </Col>
          </RowUI>
          <RowUI>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <div className={props.classes.center}>
                {getProductImage(tool.product)}
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <Title color="info" tag="h6">Latest official stable version:</Title>
              <Title color="primary" tag="h5">{tool.version}</Title>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <Title color="info" tag="h6">Fasttrack stable image:</Title>
              <Title color="primary" tag="h5">
                <b data-tip={tool.image}>{tool.image.substring(0, 8)}</b>
                { tool.image !== 'Unknown' ? '[..]' : null }
                <ReactTooltip />
              </Title>
            </Col>
          </RowUI>
        </CardContent>
      </Card>
    </Col>
  ));

  return (
    <Stack spacing="2-x">
      <Container style={{ width: '1OO%' }}>
        <RowUI>
          {toolsReference}
          <Col xs={12}>
            <Divider />
          </Col>
        </RowUI>
        <br />
        <br />
        {materialTable}
      </Container>
    </Stack>
  );
};

export default withStyles(styles)(ListTools);
