import React from 'react';

import { EditAccountContainer, AddAccountContainer } from '../../graphql/Account';
import AccountDialog from '../../components/AccountDialog/AccountDialog.component';

export const EditAccountDialog = EditAccountContainer(
  ({
    editAccount, account, open, setOpen,
  }) => (
    <AccountDialog
      addOrEditAccount={editAccount}
      account={account}
      open={open}
      setOpen={setOpen}
      isAddAccount={false}
    />
  ),
);

export const AddAccountDialog = AddAccountContainer(
  ({
    addAccount, account, open, setOpen,
  }) => (
    <AccountDialog
      addOrEditAccount={addAccount}
      account={account}
      open={open}
      setOpen={setOpen}
      isAddAccount
    />
  ),
);
