export type Row = {
  name: ReportName,
  stackName: string,
  stackInfo: string,
  logicalResourceId: string,
  stackResourceDriftStatus: string,
  resourceType: string,
}

export type Item = {
  name: ReportName
  stacks: [Stack]
}

export type Stack = {
  stackName: string
  stackId: string
  stackStatus: string
  drifts: any
}

export enum ReportName {
  DRIFTS = 'Drifts',
  ERRORS = 'Errors',
  MISSING = 'Missing',
}
