import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  AddAccountVariables, AddAccountProps, EditAccountVariables, EditAccountProps,
} from '../containers/Accounts/Accounts.type';

export const ListAccountsQuery = gql`query {
    listAccounts {
        items {
            account_id
            alias
            account_type
            application_codes
        }
    }
}`;

const AddAccountMutation = gql`mutation addAccount (
    $account: AccountInput,
){
    addAccount(account: $account){
        account_id,
        alias
    }
}`;

const EditAccountMutation = gql`mutation editAccount (
    $account: AccountInput,
){
    editAccount(account: $account){
        account_id,
    }
}`;

export const AddAccountContainer = graphql<any, void, AddAccountVariables, AddAccountProps>(
  AddAccountMutation,
  {
    options: () => ({
      refetchQueries: [
        {
          query: ListAccountsQuery,
        },
      ],
    }),
    props: ({ mutate, ownProps }) => ({
      addAccount: (params: any) => (
        mutate && mutate({
          variables: params,
        })
      ),
      ...ownProps,
    }),
  },
);

export const EditAccountContainer = graphql<any, void, EditAccountVariables, EditAccountProps>(
  EditAccountMutation,
  {
    options: () => ({
      refetchQueries: [
        {
          query: ListAccountsQuery,
        },
      ],
    }),
    props: ({ mutate, ownProps }) => ({
      editAccount: (params: any) => (
        mutate && mutate({
          variables: params,
        })
      ),
      ...ownProps,
    }),
  },
);
