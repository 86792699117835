import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Auth, Hub, Logger } from 'aws-amplify';
import { Route, Redirect } from 'react-router-dom';
import Loader from '../Loader/Loader.component';

const logger = new Logger('PrivateRoute');

const PrivateRoute = ({ render: Component, ...rest }: any) => {
  const { code } = queryString.parse(window.location.search);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [loading, setLoading] = useState(true);

  Hub.listen('auth', (data) => {
    if (code) { // the user is redirected from onelogin
      const { payload } = data;
      logger.info(`A new auth event has happened: ${payload.event}`);
      switch (payload.event) {
      case 'signOut':
        logger.info('Signed out');
        break;
      case 'signIn':
        logger.info('Logged in as:', payload.data.username);
        setIsAuthenticated(!!payload.data.username);
        break;
      default:
        break;
      }
    }
  });

  if (!code) { // the users comes directly to signin
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsAuthenticated(!!user.username);
      })
      .catch(() => {
        setIsAuthenticated(false);
      });
  }

  useEffect(() => {
    if (isAuthenticated !== undefined) {
      setLoading(false);
    }
  }, [isAuthenticated]);

  return (
    loading
      ? <Loader />
      : (
        <Route
          {...rest} // eslint-disable-line react/jsx-props-no-spreading
          render={() => (
            isAuthenticated ? <Component /> : <Redirect to="/signin" />
          )}
        />
      ));
};

export default PrivateRoute;
