import gql from 'graphql-tag';

export const ListUsersByAccountIdQuery = gql`query ListUsersByAccountIdQuery($accountId: String!) {
  getAccount(account_id: $accountId) {
    account_id
    alias
    users {
      id
      name
      passwordLastUsed
      createDate
      arn
      aspire
      path
      firstName
      lastName
      email
      lastUsedAccessKey
    }
  }
}`;

export const listAccounts = gql`query {
  listAccounts {
    items {
      account_id
      application_codes
      account_type
    }
  }
}`;

export const PromoteResultSub = gql`subscription onPromoteResult (
  $queryId: ID!
) {
    onPromoteResult(id: $queryId) {
      id
      status
      result
    }
  }
`;

export const PromoteUserMutation = gql`mutation promoteUser (
  $user: PromoteUserInput,
) {
    promoteUser(user: $user) {
      id
      status
      result
    }
  }
`;
