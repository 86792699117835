import {
  Card, CardContent, Col, Container, Divider, Row, Title,
} from '@horizon/components-react';
import React from 'react';
import HomeAccountCard from '../HomeAccountCard/HomeAccountCard.component';
import HomeCountAccountField from '../HomeCountAccount/HomeCountAccountField.component';
import HomeCountProjectField from '../HomeCountProject/HomeCountProjectField.component';
import HomeTotalCost from '../HomeTotalCost/HomeTotalCost.component';

const Home = () => (
  <Container>
    <Row>
      <Col xs={12}>
        <Title color="primary" tag="h1">Welcome to SAWS</Title>
        <Title color="info" tag="h3">a JAWS management tool</Title>
      </Col>
      <Col xs={12}>
        <Divider />
      </Col>
      <Col xs={4}>
        <Card elevation={8}>
          <CardContent>
            <Title color="info" tag="h4">Number of accounts</Title>
            <HomeCountAccountField />
          </CardContent>
        </Card>
      </Col>
      <Col xs={4}>
        <Card elevation={8}>
          <CardContent>
            <Title color="info" tag="h4">Number of projects</Title>
            <HomeCountProjectField />
          </CardContent>
        </Card>
      </Col>
      <Col xs={4}>
        <Card elevation={8}>
          <CardContent>
            <Title color="info" tag="h4">Total cost (Current Month, no VAT)</Title>
            <HomeTotalCost />
          </CardContent>
        </Card>
      </Col>
      <HomeAccountCard />
    </Row>
  </Container>

);

export default Home;
