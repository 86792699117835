import React from 'react';
import { $Keys } from 'utility-types';
import { Button } from '@horizon/components-react';
import { Row, Item } from './SSMTable.types';

export const sortRows = (_: any, sortColumn: $Keys<Row>, sortDirection: 'ASC' | 'DESC') => (rows: Row[]): Row[] => {
  const comparer = (a: Row, b: Row): 0 | 1 | -1 => {
    if (sortDirection === 'ASC') {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } if (sortDirection === 'DESC') {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
    return 0;
  };
  return [...rows].sort(comparer);
};

export const mapRowsFromItems = (items: Item[]): Row[] => (
  items.map((i) => (
    {
      name: i.name,
      type: i.type,
      value: i.value,
    }
  ))
);

const buildValue = (row: Row) => (
  <div>
    <Button contained color="primary" size="extra-small" onClick={() => { navigator.clipboard.writeText(row.value); }}>
      COPY
    </Button>
    {` ${row.value}`}
  </div>
);

type Column = {
  title: string,
  field: $Keys<Row>,
  render?: (row: Row) => string | JSX.Element,
}
export const getColumns = (): Column[] => (
  [
    { title: 'name', field: 'name' },
    { title: 'type', field: 'type' },
    { title: 'value', field: 'value', render: buildValue },
  ]
);
