import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { ChoiceField } from '@horizon/components-react';

const LIST_Accounts = gql`query {
  listAccounts {
      items {
        account_id
        account_type
        application_codes
      }
  }
}`;

export default ({ setAccount }: { setAccount: (account: string) => void }) => (
  <Query query={LIST_Accounts}>
    {({ loading, error, data }: any) => {
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      return (
        <ChoiceField
          label="Accounts"
          options={[
            {
              label: '',
              value: '',
            },
            ...data.listAccounts.items
              .map((i: any) => {
                const application_codes = JSON.stringify(i.application_codes);
                return {
                  label: `${i.account_id} - ${i.account_type} - ${application_codes}`,
                  value: i.account_id,
                };
              })]}
          onChange={(value) => setAccount(value)}
        />
      );
    }}
  </Query>
);
