import React from 'react';
import { Label, Select, MenuItem } from '@horizon/components-react';
import { withStyles } from '@material-ui/core';

const env = ['', 'dev', 'int', 'ops', 'sec', 'pro'];
const envComboboxList = env.map((e) => (
  {
    label: e,
    value: e,
  }
));

type Props = {
    classes: any,
    type: string,
    setType: (type: string) => void,
    // eslint-disable-next-line react/require-default-props
    error?: boolean,
    // eslint-disable-next-line react/require-default-props
    errorText?: string,
}

const styles = {
  root: {
    color: '#e4002b',
    fontSize: 12,
    marginTop: 2,
  },
};

const EnvironmentCombobox = ({
  classes, type, setType, error = false, errorText = undefined,
}: Props) => (
  <div>
    <Label>Environment</Label>
    <Select
      name="Environment"
      value={type}
      placeholder="dev"
      onChange={(event: any) => {
        setType(event.target.value);
      }}
      options={envComboboxList}
    >
      {envComboboxList.map((v) => (
        <MenuItem key={v.label} />
      ))}
    </Select>
    {error
      ? (
        <div className={classes.root}>
          {errorText}
        </div>
      ) : null}
  </div>
);

export default withStyles(styles)(EnvironmentCombobox);
