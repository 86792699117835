import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Container, Paper } from '@horizon/components-react';

import ListUsers from '../../containers/User/ListUsers.container';
import PromoteUser from '../../containers/User/PromoteUser.container';

const useStyles = makeStyles(() => createStyles({
  root: {
    width: 'fit-content',
  },
  feature: {
    marginBottom: '16px',
    '&:last-child': {
      margin: '0',
    },
  },
}));

const UsersPage = () => {
  const classes = useStyles();
  const [aspire, setAspire] = useState('');
  const [account, setAccount] = useState('');

  return (
    <Container className={classes.root}>
      <Paper className={classes.feature} elevation={4}>
        <Typography variant="h6">
          Promote User
        </Typography>
        <PromoteUser
          setAccount={setAccount}
          account={account}
          setAspire={setAspire}
          aspire={aspire}
        />
      </Paper>
      <Paper className={classes.feature} elevation={4}>
        <Typography variant="h6">
          List Users
        </Typography>
        <ListUsers />
      </Paper>
    </Container>
  );
};

export default (UsersPage);
