export type Row = {
  aspire: string
  accountId: string
  accountType: string
  accountStatus: string
  reportStatus: string
}

export type Item = {
  aspire: string
  name: string
  status: string
  cognito_airbus_domain: string
  accounts: Accounts[]
}

export type Accounts = {
  account_id: string
  account_type: AccountType
  status: string
  report: Report
  application_codes: string[]
}

export type Report = {
  drifts: any
  errors: any
  missing: any
  status: AccountStatus
}

export enum AccountStatus {
  VALID = 'VALID',
  FAILED = 'FAILED',
  DRIFTED = 'DRIFTED',
  INCOMPLETE = 'INCOMPLETE'
}

export enum AccountType {
  DEV = 'dev',
  INT = 'int',
  PRO = 'pro',
  OPS = 'ops'
}

export enum ProjectStatus {
  OK = 'green',
  UNKNOWN = 'gray',
  FAILED_DRIFTED = 'red',
  INCOMPLETE = 'orange'
}
